// Save files to REST API
import { ExcalidrawElement, FileId } from "../../element/types";
import { getSceneVersion } from "../../element";
import Portal from "../collab/Portal";
import { restoreElements } from "../../data/restore";
import {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "../../types";
import { FILE_CACHE_MAX_AGE_SEC } from "../app_constants";
import { decompressData } from "../../data/encode";
import { encryptData, decryptData } from "../../data/encryption";
import { MIME_TYPES } from "../../constants";
import { reconcileElements } from "../collab/reconciliation";
import { getSyncableElements, SyncableExcalidrawElement } from ".";


interface FirebaseStoredScene {
  sceneVersion: number;
  iv: firebase.default.firestore.Blob;
  ciphertext: firebase.default.firestore.Blob;
}
 
const decryptElements = async (
    data: FirebaseStoredScene,
    roomKey: string,
  ): Promise<readonly ExcalidrawElement[]> => {
    const ciphertext = data.ciphertext.toUint8Array();
    const iv = data.iv.toUint8Array();
  
    const decrypted = await decryptData(iv, ciphertext, roomKey);
    const decodedData = new TextDecoder("utf-8").decode(
      new Uint8Array(decrypted),
    );
    return JSON.parse(decodedData);
  };
   
class FirebaseSceneVersionCache {
  private static cache = new WeakMap<SocketIOClient.Socket, number>();
  static get = (socket: SocketIOClient.Socket) => {
    return FirebaseSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: SocketIOClient.Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    FirebaseSceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };
}

export const loadFromAPI = async (
  roomId: string,
  roomKey: string,
  socket: SocketIOClient.Socket | null,
): Promise<readonly ExcalidrawElement[] | null> => {
 
  return null;
  
  const storedScene = {} as FirebaseStoredScene;
  const elements = getSyncableElements(
    await decryptElements(storedScene, roomKey),
  );

  /*
  const firebase = await loadFirestore();
  const db = firebase.firestore();

  const docRef = db.collection("scenes").doc(roomId);
  const doc = await docRef.get();
  if (!doc.exists) {
    return null;
  }
  const storedScene = doc.data() as FirebaseStoredScene;
  const elements = getSyncableElements(
    await decryptElements(storedScene, roomKey),
  );

  if (socket) {
    FirebaseSceneVersionCache.set(socket, elements);
  }
  */

  return restoreElements(elements, null);
};
  
export const saveFilesToAPI = async ({
    prefix,
    files,
  }: {
    prefix: string;
    files: { id: FileId; buffer: Uint8Array }[];
  }) => {
    //  const firebase = await loadFirebaseStorage();
    console.log("saveFilesToAPI prefix: " + prefix);
    // saveFilesToAPI prefix: /files/rooms/eebee43bf55d49236f23
    console.log("saveFilesToAPI files");
    // array len 0
    console.log(files);

    const erroredFiles = new Map<FileId, true>();
    const savedFiles = new Map<FileId, true>();
  
    await Promise.all(
      files.map(async ({ id, buffer }) => {
        try {
          console.log("Saving to API");
          console.log(`${prefix}/${id}`);
          erroredFiles.set(id, true);
        /*
          await firebase
            .storage()
            .ref(`${prefix}/${id}`)
            .put(
              new Blob([buffer], {
                type: MIME_TYPES.binary,
              }),
              {
                cacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`,
              },
            );
          savedFiles.set(id, true);
        */
        } catch (error: any) {
          erroredFiles.set(id, true);
        }
      }),
    );
  
    return { savedFiles, erroredFiles };
  };

export const isSavedToAPI = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);
    console.log("sceneVersion is " + sceneVersion);
    return FirebaseSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const saveToAPI = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  console.log(" -> saveToAPI, called with (portal, elements, appState):");
  console.log(portal);
  console.log(elements);
  console.log(appState);
  const { roomId, roomKey, socket } = portal;
  if (
    // bail if no room exists as there's nothing we can do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToAPI(portal, elements)
  ) {
    return false;
  }

  return { reconciledElements: [] };

  /*
  const firebase = await loadFirestore();
  const firestore = firebase.firestore();

  const docRef = firestore.collection("scenes").doc(roomId);

  const savedData = await firestore.runTransaction(async (transaction) => {
    const snapshot = await transaction.get(docRef);

    if (!snapshot.exists) {
      const sceneDocument = await createFirebaseSceneDocument(
        firebase,
        elements,
        roomKey,
      );

      transaction.set(docRef, sceneDocument);

      return {
        elements,
        reconciledElements: null,
      };
    }

    const prevDocData = snapshot.data() as FirebaseStoredScene;
    const prevElements = getSyncableElements(
      await decryptElements(prevDocData, roomKey),
    );

    const reconciledElements = getSyncableElements(
      reconcileElements(elements, prevElements, appState),
    );

    const sceneDocument = await createFirebaseSceneDocument(
      firebase,
      reconciledElements,
      roomKey,
    );

    transaction.update(docRef, sceneDocument);
    return {
      elements,
      reconciledElements,
    };
  });

  FirebaseSceneVersionCache.set(socket, savedData.elements);

  return { reconciledElements: savedData.reconciledElements };
  */
};
